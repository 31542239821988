import { message } from 'antd';
import axios from 'axios';
import showErrorMsg from './ErrorMsgService';
import Logout from '../function/Logout';
import * as Sentry from '@sentry/react';
const token = localStorage.getItem('userId');

export const apiRequest = () => {
    const lpgToken = localStorage.getItem('userId');
    return axios.create({
        baseURL: `${process.env.REACT_APP_API_KEY}`,
        headers: {
            Authorization: lpgToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
};

export const apiFormRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_KEY}`,
    headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
    },
});

export const fetchApi = async (requestIns, params, body) => {
    let err;
    const res = await requestIns(params, body).catch((error) => {
        handleError(error, { params, body });
        err = error;
    });
    return res && res.data ? res.data : err;
};

export const fetchWithPage = async (requestIns, pagination, filterDates, searchParams) => {
    const pathVars = filterDates
        ? { firstISOString: filterDates[0], endISOString: filterDates[1] }
        : {};

    const res = await requestIns(
        { size: pagination.pageSize, page: pagination.current - 1 },
        { ...pathVars, ...searchParams },
    ).catch((error) => {
        handleError(error, { pagination, filterDates, searchParams });
    });

    return res && res.data ? res.data : undefined;
};

const handleError = (error, body) => {
    Sentry.captureException(error, {
        extra: {
            body,
            response: error.response
                ? {
                      status: error.response.data.status,
                      data: JSON.stringify(error.response.data.data),
                      message: JSON.stringify(error.response.data.message),
                  }
                : null,
            request: {
                url: error.config.url,
                method: error.config.method,
                data: error.config.data,
            },
        },
    });
    if (error.response) {
        if (error.response.data?.message?.errorCode === 0) {
            message.error(error.response.data?.message?.content);
        } else if (error.response.data.status === 403) {
            Logout();
        } else if (typeof error.response.data.message === 'object')
            message.error({
                content: error.response.data.message.content,
                key: 'msgKey',
            });
        return error;
    } else if (error.message) {
        showErrorMsg(error);
        return error;
    }
};
