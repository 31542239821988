import { message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { FetchCusData } from '../PhoneRing/PhoneNotice/FetchCusData';

export const websocket = () => {
    const token = localStorage.getItem('userId');
    const [topic, setTopic] = useState('reconnection');
    const [phoneLine, setPhoneLine] = useState();
    const [SupplierPhoneLine, setSupplierPhoneLine] = useState([]);
    const [phoneRing, setPhoneRing] = useState([]);
    const [phoneCus, setPhoneCus] = useState({});
    const [notice, setNotice] = useState({});
    const [ring, setRing] = useState(null);
    const [phoneLineList, setPhoneLineList] = useState([]);
    const socketRef = useRef(null); // 使用 useRef 存储 socket 实例
    const reconnectInterval = useRef(null); // 存储重连定时器
    const url = `${process.env.REACT_APP_WEBSOCKET_KEY}?jwt_token=${token}&role=supplier`;
    const key = 'updatable';
    let list = [{}, {}, {}, {}, {}, {}, {}, {}];

    const socketInit = () => {
        if (socketRef.current?.connected) {
            clearReconnect();
            return;
        }

        if (socketRef.current) {
            socketRef.current.off(); // 移除所有事件監聽
            socketRef.current.disconnect(); // 確保連線中斷
        }

        try {
            setTopic('reconnection');
            socketRef.current = io(url, { transports: ['websocket'] });
            setupSocketListeners(socketRef.current);
            clearReconnect();
        } catch (e) {
            message.error({ content: '讀取失敗', key });
        }
    };

    const setupSocketListeners = (socket) => {
        socket.on('connect', () => {
            setTopic('connection');
            const localphoneLine = localStorage.getItem('phoneLine')?.split(',');
            if (localphoneLine) {
                setPhoneLine(localphoneLine);
            }
        });

        socket.on('supplier-phone-lines', (accPhoneLines) => {
            setTopic('supplier-phone-lines');
            setSupplierPhoneLine(accPhoneLines.accessPhoneLine);
        });

        socket.on('user-phone-lines', (userPhoneLines) => {
            setTopic('user-phone-lines');
            const localphoneLine = localStorage.getItem('phoneLine')?.split(',');
            if (!localphoneLine) {
                setPhoneLine(userPhoneLines.accessPhoneLine);
                localStorageSetItem(userPhoneLines.accessPhoneLine);
            }
            setPhoneRing(userPhoneLines.phone_ring_list);
        });

        socket.on('refresh-phone-line-setting', (refreshPhone) => {
            setTopic('refresh-phone-line-setting');
            console.warn(refreshPhone);
            let localphoneLine = localStorage.getItem('phoneLine')?.split(',');
            if (refreshPhone.accessPhoneLine) {
                localStorageSetItem(refreshPhone.accessPhoneLine);
            }
            refreshPhone.accessPhoneLine && setPhoneLine([...refreshPhone.accessPhoneLine]);
            refreshPhone.phone_ring_list && setPhoneRing(refreshPhone.phone_ring_list);
            refreshPhone.supplierPhoneLineList &&
                setSupplierPhoneLine(refreshPhone.supplierPhoneLineList);
            if (refreshPhone.phone_ring) {
                if (refreshPhone.phone_ring.is_ringing) {
                    setRing({ ...refreshPhone.phone_ring, is_ringing: true });
                    setPhoneCus({
                        phone_number: refreshPhone.phone_number,
                        phone_line: refreshPhone.phone_ring.phone_line,
                    });
                    if (
                        !phoneLineList.find(
                            (data) => data.phone_line === refreshPhone.phone_ring.phone_line,
                        ) &&
                        localphoneLine[refreshPhone.phone_ring.phone_line] === 'true'
                    ) {
                        list[refreshPhone.phone_ring.phone_line] = {
                            loading: true,
                            phone_line: refreshPhone.phone_ring.phone_line,
                            phoneCus: { phone_number: refreshPhone.phone_number },
                        };
                        setPhoneLineList([...list].filter((item) => item.phoneCus));

                        fetchCusData(
                            refreshPhone.phone_number,
                            refreshPhone.phone_ring.phone_line,
                            list,
                        );
                    }
                } else {
                    setRing({ ...refreshPhone.phone_ring, is_ringing: false });
                    setPhoneCus({});
                    list[refreshPhone.phone_ring.phone_line] = {};
                    setPhoneLineList([...list].filter((item) => item.phoneCus));
                }
            }
        });

        socket.on('notification', (newNotice) => {
            setNotice(newNotice);
        });

        socket.on('disconnect', () => {
            setTopic('reconnection');
            setupReconnect();
        });

        socket.on('connect_error', () => {
            setTopic('connection_error');
            setupReconnect();
        });
    };

    const setupReconnect = () => {
        if (reconnectInterval.current || (socketRef.current && socketRef.current.connected)) {
            return;
        }

        reconnectInterval.current = setInterval(() => {
            socketInit();
        }, 10000); // 每 10 秒嘗試重連
    };

    const clearReconnect = () => {
        if (reconnectInterval.current) {
            clearInterval(reconnectInterval.current);
            reconnectInterval.current = null;
        }
    };

    const localStorageSetItem = (phoneLine) => {
        if (phoneLine) {
            localStorage.setItem('phoneLine', phoneLine);
            document.cookie = `phoneLine=${phoneLine}`;
        } else {
            localStorage.removeItem('phoneLine');
            document.cookie = `phoneLine=`;
        }
    };

    const settingPhoneRing = (value, index) => {
        message.loading({ content: '設定中', key });
        const array = [...phoneLine];
        array.splice(index, 1, String(value));
        socketRef.current?.emit('setUserPhoneLines', {
            accessPhoneLine: array,
        });
        localStorage.setItem('phoneLine', array);
        document.cookie = `phoneLine=${array}`;
        message.success({ content: '設定完成', key });
    };

    const fetchCusData = async (phone_number, phone_line, newList) => {
        const cusData = await FetchCusData({ phone_number: phone_number });
        const SlicePhoneIndex = newList.findIndex((data) => data.phone_line === phone_line);
        cusData?.customerList.length === 1
            ? (list[SlicePhoneIndex] = {
                  phoneCus: {
                      ...cusData,
                      ...{
                          customerInfos:
                              cusData.customerList.length > 0 ? cusData.customerList[0] : {},
                      },
                      phone_number,
                  },
                  payRecord: cusData.customerPayupRecords,
                  phone_line,
              })
            : (list[SlicePhoneIndex] = {
                  phoneCus: { ...cusData, phone_number },
                  ...{ manyCus: cusData?.customerList.length > 0 ? true : false },
                  phone_line,
              });
        setPhoneLineList([...list].filter((item) => item.phoneCus));
    };

    useEffect(() => {
        if (token) {
            socketInit();
        }
        return () => {
            // 清理連線
            if (socketRef.current) {
                socketRef.current.disconnect();
                socketRef.current = null;
            }
            clearReconnect();
        };
    }, [token]);

    return {
        topic,
        phoneLine,
        SupplierPhoneLine,
        phoneRing,
        ring,
        phoneCus,
        phoneLineList,
        notice,
        settingPhoneRing,
    };
};
