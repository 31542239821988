import { Helmet, HelmetProvider } from 'react-helmet-async';

const HelmetData = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta
                    httpEquiv='Content-Security-Policy'
                    content={`
                    default-src 'self' https://*.artifactdev.tw wss://*.artifactdev.tw http://localhost:3000 https://*.amazonaws.com https://127-0-0-1.grandmall.me:9119/print https://*.googleapis.com https://*.sentry.io;
                    base-uri 'self';
                    block-all-mixed-content;
                    font-src 'self' https: data:;
                    form-action 'self' https://*.artifactdev.tw ;
                    img-src 'self' data: blob: https://*.artifactdev.tw  https://*.amazonaws.com http://localhost:3000 https://*.openstreetmap.org;
                    object-src 'self' https://*.artifactdev.tw ;
                    script-src 'self' 'unsafe-eval';
                    script-src-attr 'none';
                    worker-src 'self' blob: ;
                    style-src 'self' 'unsafe-inline' https://*.artifactdev.tw ;
                    upgrade-insecure-requests
                `}
                />
                <meta name='poweredby' content='false' />
                <meta name='Cross-Origin-Embedder-Policy' content='require-corp' />
                <meta name='Cross-Origin-Resource-Policy' content='same-origin' />
                <meta name='Expect-CT' content='max-age=0' />
                <meta name='Origin-Agent-Cluster' content='?1' />
                <meta name='Referrer-Policy' content='no-referrer' />
                <meta
                    name='Strict-Transport-Security'
                    content='max-age=15552000; includeSubDomains'
                />
                <meta name='X-Content-Type-Options' content='nosniff' />
                <meta name='X-DNS-Prefetch-Control' content='off' />
                <meta name='X-Download-Options' content='noopen' />
                <meta name='X-Frame-Options' content='SAMEORIGIN' />
                <meta name='X-Permitted-Cross-Domain-Policies' content='none' />
                <meta name='X-XSS-Protection' content='0' />
            </Helmet>
        </HelmetProvider>
    );
};
export default HelmetData;
