import { fetchApi } from '../services/ApiService';
import { unsubscribeFCM } from '../services/PublicApi';
import { message } from 'antd';

const Logout = () => {
    message.loading('登出中...');
    if (localStorage.getItem('firebaseToken')) {
        fetchApi(unsubscribeFCM, {
            fcmToken: localStorage.getItem('firebaseToken'),
        }).then(() => {
            localStorage.removeItem('userId');
            document.cookie = 'userId=;';
            history.go('/loading');
        });
    } else {
        localStorage.removeItem('userId');
        document.cookie = 'userId=;';
        history.go('/loading');
    }
};
export default Logout;
