import { apiRequest } from './ApiService';

export const getCusDetailByPhone = (body) =>
    apiRequest().get('/aw-api/basic/customer/phone-search', {
        params: { ...body },
    });

export const getCusDetailByPhoneHome2 = (body) =>
    apiRequest().get('/aw-api/homepage2/customer/phone-search', {
        params: { ...body },
    });

export const getCusDetailByID = (body) =>
    apiRequest().get('/aw-api/basic/customer', {
        params: { ...body },
    });

export const getCusDetailByIDHome2 = (body) =>
    apiRequest().get('/aw-api/homepage2/customer', {
        params: { ...body },
    });

export const getCusOrderByAddress = (body) =>
    apiRequest().get('/aw-api/basic/customer/order-records', {
        params: { ...body },
    });

export const getCusOrderByAddressHome2 = (body) =>
    apiRequest().get('/aw-api/homepage2/customer/order', {
        params: { ...body },
    });

export const getCusArrearByAddressHome2 = (body) =>
    apiRequest().get('/aw-api/homepage2/customer/arrears', {
        params: { ...body },
    });

export const getCusPayByAddress = (body) =>
    apiRequest().get('/aw-api/basic/customer/payup-records?page=0&size=5', {
        params: { ...body },
    });

export const getCusArrearRecords = (body) =>
    apiRequest().get('/aw-api/homepage2/customer/arrears', {
        params: { ...body },
    });
